import useSWR from 'swr'
import api from './api'

const getVersionFetcher = url => api.get(url).then(res => res.data)

export function useGetServerVersion() {
  const { data, error, isLoading } = useSWR('/version', getVersionFetcher)

  return {
    version: data,
    isLoadingVersion: isLoading,
    isErrorVersion: error,
  }
}
