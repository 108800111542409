import { signOut } from 'next-auth/react'

const deleteStorage = () => {
  localStorage.clear()
  sessionStorage.clear()
}

export const deleteCookies = async () => {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
  }

  const cookiesStore = await document?.cookieStore?.getAll()
  cookiesStore?.forEach(cookie => {
    document?.cookieStore?.delete(cookie.name)
  })
}

async function clearSiteData(signOutParam = true) {
  deleteStorage()
  await deleteCookies()
  if (signOutParam) {
    await signOut()
  }
}

export default clearSiteData
