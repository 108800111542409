import { forwardRef } from 'react'
import { Text as ChakraText } from '@chakra-ui/react'
import { css, jsx } from '@emotion/react'

// Prop           CSS Property    Theme Key      Ej
// fontFamily     font-family     fonts
// fontSize       font-size       fontSizes      md
// fontWeight     font-weight     fontWeights    bolder
// lineHeight     line-height     lineHeights
// letterSpacing  letter-spacing  letterSpacings
// color          color           colors         red.100
// textAlign      text-align      none
// fontStyle      font-style      none
// textTransform  text-transform  none
// textDecoration text-decoration none
// textShadow     text-shadow     shadows

// https://chakra-ui.com/docs/typography/text

const styles = (block, center) => css`
  display: ${block ? 'block' : 'inline'};
  ${center &&
  css`
    text-align: center;
  `}
`

export const Text = forwardRef(({ block, css: propsCss = '', className = '', center, ...props }, ref) => {
  return (
    <ChakraText ref={ref} {...props} className={`${propsCss} ${className}`} css={styles(block, center)}>
      {props.children}
    </ChakraText>
  )
})
