import { css, jsx } from '@emotion/react'
import { forwardRef } from 'react'
import { ModalCloseButton as ChakraModalCloseButton, LightMode } from '@chakra-ui/react'
import theme from '../../../constants/theme'

// https://chakra-ui.com/docs/overlay/modal
// https://chakra-ui.com/docs/components/close-button

const styles = {
  activePrimary: css`
    &:focus,
    [data-focus] {
      box-shadow: 0 0 0 3px ${theme.colors.primary.base};
    }
  `,
}

export const ModalCloseButton = forwardRef(({ lightMode, colorScheme, ...props }, ref) => {
  const stylesProps = colorScheme === 'primary' || colorScheme === 'primaryButton' ? { css: styles.activePrimary } : {}
  const ChakraModalCloseButtonComp = (
    <ChakraModalCloseButton ref={ref} {...stylesProps} {...props} colorScheme={colorScheme} />
  )

  return lightMode ? <LightMode>{ChakraModalCloseButtonComp}</LightMode> : ChakraModalCloseButtonComp
})
