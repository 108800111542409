import { useBreakpointValue } from '@chakra-ui/react'
import { useMemo } from 'react'
import { breakpointsValues } from '../../constants'
import responsive from '../responsive'

function useResponsive(...values) {
  const breakpoint = useBreakpointValue(breakpointsValues)
  return useMemo(() => responsive(breakpoint, ...values), [breakpoint])
}

export default useResponsive
