import { css, jsx } from '@emotion/react'
import { forwardRef } from 'react'
import { ModalContent as ChakraModalContent, useColorMode } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import theme from '../../../constants/theme'
import useResponsive from '../../../utils/hooks/useResponsive'
import { appHeight } from '../../../store'

// https://chakra-ui.com/docs/overlay/modal

const styles = (colorMode, fullSizeStyles, height) => css`
  background: ${theme.colors.background[colorMode]};
  transition: background ${theme.transition.duration.background}, border ${theme.transition.duration.color};
  ${fullSizeStyles &&
  css`
    margin: 0;
    max-height: ${height};
    height: ${height};
    border-radius: 0;
  `}
`

export const ModalContent = forwardRef(({ css: propsCss = '', className = '', ...props }, ref) => {
  const { colorMode } = useColorMode()
  const modalSize = useResponsive('full', 'md')
  const { px: height } = useRecoilValue(appHeight)

  return (
    <ChakraModalContent
      ref={ref}
      {...props}
      size={modalSize}
      className={`${propsCss} ${className}`}
      css={styles(colorMode, modalSize === 'full', height)}
    >
      {props.children}
    </ChakraModalContent>
  )
})
