import { SessionProvider } from 'next-auth/react'

// https://next-auth.js.org/getting-started/client#options
const options = {
  // re-fetch Interval tells windows / tabs that are signed in to keep sending
  // a keep alive request (which extends the current session expiry) to
  // prevent sessions in open windows from expiring. Value in seconds.
  //
  // Note: If a session has expired when re-fetch Interval is triggered, all open
  // windows / tabs will be updated to reflect the user is signed out.
  refetchInterval: 0,
  refetchOnWindowFocus: typeof navigator !== 'undefined' && navigator.onLine,
  refetchWhenOffline: false,
}

function NextAuth({ children, session }) {
  return (
    <SessionProvider session={session} {...options}>
      {children}
    </SessionProvider>
  )
}

export default NextAuth
