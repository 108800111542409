import useSWR, { mutate } from 'swr'
import api from './api'

export const editUser = async payload => {
  const response = await api.put('/me', { ...payload })
  await mutate('/me')
  return response.data
}

const getUserFetcher = url => api.get(url).then(res => res.data)
const object = {}
export function useGetUser() {
  const { data, error, isLoading } = useSWR('/me', getUserFetcher)

  return {
    user: data || object,
    loadingUser: isLoading,
    errorUser: error,
  }
}