import { useSession } from 'next-auth/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useSetRecoilState } from 'recoil'
import { useGetUser } from '../services/user'
import { useGetBilling } from '../services/billing'
import { premiumPlanModal } from '../store'
import { Toast } from '../components/chakra/Toast'

// const { isLoggedIn, loadingSession, session } = useIsLoggedIn()
// https://next-auth.js.org/getting-started/client#usesession
export function useIsLoggedIn() {
  const { data: session, status } = useSession()
  const loading = status === 'loading'
  const isLoggedIn = status === 'authenticated'

  return {
    isLoggedIn,
    loadingSession: loading,
    session,
  }
}

// const { user, isLoadingUser, isErrorUser } = useUser()
export function useUser() {
  const { user, loadingUser, errorUser } = useGetUser()

  return {
    user,
    loadingUser,
    errorUser,
  }
}

// const { autoTagging, loadingAutoTagging } = useAutoTagging()
export function useAutoTagging() {
  const { user, loadingUser } = useGetUser()

  return {
    autoTagging: !!user?.settings?.autoTagging,
    loadingAutoTagging: loadingUser,
  }
}

// const styles = useShowAccented({ accented: 'red', normal: 'blue'})
export function useShowAccented({ accented, normal }) {
  const router = useRouter()
  const { pathname } = router
  const richDarkBackgroundRoutes = useMemo(
    () => [
      '/',
      '/login',
      '/email-sent',
      '/about',
      '/terms-of-service',
      '/privacy-policy',
      '/email-sent',
      '/login-error',
      '/pricing',
      '/email-providers',
    ],
    []
  )
  const isAcented = !!useMemo(
    () => richDarkBackgroundRoutes.find(route => route === pathname),
    [richDarkBackgroundRoutes, pathname]
  )
  return isAcented ? accented : normal
}

export function usePremiumFeature() {
  const { billing, loadingBilling, errorBilling } = useGetBilling()
  const setPremiumPlanModal = useSetRecoilState(premiumPlanModal)

  return useCallback(() => {
    if (billing.isPremium) return true

    if (loadingBilling) {
      Toast.close('loading-billing-toast')
      Toast({
        id: 'loading-billing-toast',
        title: 'Loading billing plan',
        description: 'We are fetching your plan info please try again...',
        status: 'info',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
      return
    }

    if (errorBilling) {
      Toast.close('loading-billing-toast')
      Toast.close('error-billing-toast')
      Toast({
        id: 'error-billing-toast',
        title: 'Error loading billing plan',
        description: 'Error fetching your plan info please try again later',
        status: 'info',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
      return
    }

    setPremiumPlanModal(true)
    return false
  }, [billing, loadingBilling, errorBilling])
}

export function useIsPremium() {
  const { billing, loadingBilling, errorBilling } = useGetBilling()

  if (errorBilling) {
    Toast.close('loading-billing-toast')
    Toast.close('error-billing-toast')
    Toast({
      id: 'error-billing-toast',
      title: 'Error loading billing plan',
      description: 'Error fetching your plan info please try again later',
      status: 'info',
      duration: 9000,
      isClosable: true,
      position: 'top-right',
    })
  }

  return useMemo(
    () => (loadingBilling || errorBilling ? false : billing.isPremium),
    [billing, loadingBilling, errorBilling]
  )
}

export function useIsChrome() {
  const [isChrome, setIsChrome] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsChrome(!!window.chrome)
    }
  }, [])

  return isChrome
}
