import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

const SleekWithNoSSR = dynamic(() => import('./SleekWidget'), { ssr: false })
const allowRoutes = ['/dashboard']
const id = process.env.NEXT_PUBLIC_SLEEK_WEBSITE_ID

function Sleek() {
  const router = useRouter()
  const { pathname } = router
  const renderCrisp = allowRoutes.some(item => pathname.includes(item))
  return renderCrisp ? <SleekWithNoSSR id={id} /> : null
}

export default Sleek
