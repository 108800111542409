import { SWRConfig } from 'swr'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { revalidateOnFocus as recoilRevalidateOnFocus } from '../../store'

function Swr({ children }) {
  const revalidateOnFocus = useRecoilValue(recoilRevalidateOnFocus)

  const options = useMemo(
    () => ({
      revalidateOnFocus,
      shouldRetryOnError: false,
    }),
    [revalidateOnFocus]
  )

  return <SWRConfig value={options}>{children}</SWRConfig>
}

export default Swr
