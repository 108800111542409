import { useAnalyticsIdentify, useAnalyticsPage } from '../../utils/analytics'

function AnalyticsImp() {
  useAnalyticsIdentify()
  useAnalyticsPage()

  // const snapshot = useRecoilSnapshot()
  // useEffect(() => {
  //   const version = snapshot?._store?.getState()?.currentTree?.version
  //   if (typeof version !== 'undefined') {
  //     analyticsTrack('app_state_changed', { version })
  //   }
  // }, [snapshot?._store?.getState()?.currentTree?.version])

  return null
}

export default AnalyticsImp
