import { memo, useEffect } from 'react'
import { Toast } from '../chakra/Toast'
import { Text } from '../chakra/Text'
import { Button } from '../chakra/Button'
import useIsBrowserOffline from '../../utils/hooks/useIsBrowserOffline'

function AppOfflineIndicator() {
  const online = useIsBrowserOffline()

  useEffect(() => {
    try {
      if (!online) {
        const text = {
          title: 'App is Offline ⚠️',
          description: (
            <div>
              <Text block mb="10px">
                Looks like you're offline, please reload to reconnect
              </Text>
              <Button size="xs" onClick={() => window?.location?.reload()} variant="outline" colorScheme="white">
                Reload
              </Button>
            </div>
          ),
        }
        Toast.close('app-is-offline')
        Toast({
          id: 'app-is-offline',
          status: 'warning',
          duration: 20000,
          isClosable: true,
          position: 'top-right',
          ...text,
        })
      }
    } catch {
      // Fail silently
    }
  }, [online])

  return null
}

export default memo(AppOfflineIndicator)
