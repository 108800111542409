import { forwardRef } from 'react'
import { Alert as ChakraAlert } from '@chakra-ui/react'

// https://chakra-ui.com/docs/feedback/alert

export const Alert = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraAlert ref={ref} {...props}>
      {props.children}
    </ChakraAlert>
  )
})
