import Head from 'next/head'
import { publicSiteUrl } from '../../constants'

function Meta() {
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1" />
      <link rel="icon" type="image/png" href={`${publicSiteUrl}/favicons/favicon.png`} />
      <title>BackToIt.io — All of yours get back to stored in one place</title>

      {/* <link rel="apple-touch-icon" sizes="57x57" href="icons/apple-icon-57x57.png" /> */}
      {/* <link rel="apple-touch-icon" sizes="60x60" href="icons/apple-icon-60x60.png" /> */}
      {/* <link rel="apple-touch-icon" sizes="72x72" href="icons/apple-icon-72x72.png" /> */}
      {/* <link rel="apple-touch-icon" sizes="76x76" href="icons/apple-icon-76x76.png" /> */}
      {/* <link rel="apple-touch-icon" sizes="114x114" href="icons/apple-icon-114x114.png" /> */}
      {/* <link rel="apple-touch-icon" sizes="120x120" href="icons/apple-icon-120x120.png" /> */}
      {/* <link rel="apple-touch-icon" sizes="144x144" href="icons/apple-icon-144x144.png" /> */}
      {/* <link rel="apple-touch-icon" sizes="152x152" href="icons/apple-icon-152x152.png" /> */}
      {/* <link rel="apple-touch-icon" sizes="180x180" href="icons/apple-icon-180x180.png" /> */}

      <link rel="apple-touch-icon" sizes="180x180" href="icons/apple-touch-icon.png" />

      <link rel="icon" type="image/png" sizes="192x192" href="icons/android-icon-192x192.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="favicons/favicon-96x96.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="favicons/favicon-16x16.png" />

      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#6677E5" />
      <meta name="msapplication-TileImage" content="icons/ms-icon-144x144.png" />
      <meta name="theme-color" content="#8592EA" />

      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
    </Head>
  )
}

export default Meta
