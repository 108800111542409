import { css, jsx } from '@emotion/react'
import { forwardRef } from 'react'
import theme from '../constants/theme'
import { Box } from './chakra/Box'

const defaultSpace = theme.space[9]
const calcSize = size => (size ? `calc(${size} * ${theme.space[2]})` : defaultSpace)

const styles = props => css`
  margin-right: ${props.left ? '0.5em' : '0'};
  margin-left: ${props.right ? '0.5em' : '0'};
  display: ${props.block ? 'block' : 'inline-block'};
  ${props.onClick ? 'cursor: pointer' : ''};
  align-items: center;
  /* height: ${props.sizeH ? calcSize(props.sizeH) : calcSize(props.size)}; */
  width: ${props.sizeW ? calcSize(props.sizeW) : calcSize(props.size)};

  svg {
    display: inline-block;
    /* height: ${props.sizeH ? calcSize(props.sizeH) : calcSize(props.size)}; */
    width: ${props.sizeW ? calcSize(props.sizeW) : calcSize(props.size)};
    overflow: visible;
    vertical-align: middle;
  }
`

const Icon = forwardRef(
  ({ icon: Svg, left, right, block, size, sizeW, sizeH, css: propsCss = '', className = '', ...props }, ref) => {
    return (
      <Box
        ref={ref}
        {...props}
        className={`${propsCss} ${className}`}
        css={styles({ left, right, block, size, sizeW, sizeH })}
      >
        {Svg && <Svg />}
      </Box>
    )
  }
)

export default Icon
