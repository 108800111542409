import { memo, useRef } from 'react'
import { Button } from './chakra/Button'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from './chakra/Modal'

function Alert({
  isOpen,
  title,
  body,
  onSuccess,
  onClose,
  hideCancel,
  cancelLabel = 'Cancel',
  successLabel = 'Ok',
  returnFocusOnClose = false,
  closeOnOverlayClick = false,
  dangerButton = false,
  isLoading = false,
  successButtonProps = {},
}) {
  const openRef = useRef()

  return (
    <Modal
      isOpen={isOpen}
      initialFocusRef={!dangerButton && openRef}
      onClose={onClose}
      returnFocusOnClose={returnFocusOnClose}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold">
          {title}
        </ModalHeader>
        {onClose && <ModalCloseButton colorScheme="primaryButton" size="sm" disabled={isLoading} />}
        <div>
          <ModalBody>{body}</ModalBody>
        </div>

        <ModalFooter>
          {!hideCancel && (
            <Button onClick={onClose} size="sm" disabled={isLoading}>
              {cancelLabel}
            </Button>
          )}
          <Button
            ref={openRef}
            colorScheme={dangerButton ? 'red' : 'primaryButton'}
            lightMode
            ml={3}
            size="sm"
            onClick={onSuccess}
            isLoading={isLoading}
            {...successButtonProps}
          >
            {successLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default memo(Alert)
