import { darken } from 'polished'
import { extendTheme } from '@chakra-ui/react'

const customTheme = {
  // https://coolors.co/
  colors: {
    background: {
      light: '#FFF',
      dark: '#272727',
      richBlack: '#111117',
    },
    sidebar: {
      light: '#EDF2F7',
      dark: 'rgba(255,255,255,0.04)',
      border: {
        light: '#DAD9D9',
        dark: '#0E0E0E',
      },
    },
    drawer: {
      dark: '#2F2F2F',
      light: '#EDF2F7',
    },
    text: {
      light: '#464D6C',
      dark: '#FFF',
      gray: '#4d4d4d',
      accentedGray: '#A4A4A4',
    },
    border: {
      light: '#dddddd',
      dark: '#424242',
    },
    custom: {
      gray: '#A5A8B6',
      lightGray: '#ECEDF1',
      green: '#1ED639',
      black: '#1f1f1f',
      outline: '#A8B1F0',
      red: '#f03232',
      purple: '#8661ff',
      pink: '#f89fff',
      darkBlue: '#1C1C26',
      verifiedGreen : '#11b329'
    },
    card: {
      hover: {
        dark: '#363636',
        light: '#e8ecf1',
      },
      background: {
        dark: '#2c2c2c',
        light: '#f5faff',
      },
    },
    primary: {
      base: '#6677E5',
      50: '#DCE0F9',
      100: '#CBD0F6',
      200: '#B9C1F3', // Dark mode
      300: '#A8B1F0',
      400: '#8592EA',
      500: '#6677E5', // Main
      600: '#5164E1',
      700: '#3F54DE',
      800: '#2E45DC',
      900: '#233BD1',
    },
    secondary: {
      base: '#464D6C',
      50: '#B5BACF',
      100: '#9CA3BF',
      200: '#848BAE', // Dark mode
      300: '#6B749E',
      400: '#596188',
      500: '#464D6C', // Main
      600: '#383E56',
      700: '#30354A',
      800: '#282C3E',
      900: '#202331',
    },
    primaryButton: {
      50: '#DCE0F9',
      100: '#CBD0F6',
      200: '#B9C1F3', // Dark mode
      300: '#A8B1F0',
      400: '#8592EA',
      500: '#6677E5', // Main
      600: darken(0.04, '#6677E5'), // Hover
      700: darken(0.06, '#6677E5'), // Clicked
      800: '#2E45DC',
      900: '#233BD1',
    },
    accented: {
      base: '#6333FF',
      50: '#B199FF',
      100: '#A185FF',
      200: '#9270FF', // Dark mode
      300: '#825CFF',
      400: '#7247FF',
      500: '#6333FF', // Main
      600: '#531FFF',
      700: '#430AFF',
      800: '#3900F5',
      900: '#3400E0',
    },
    accentedButton: {
      base: '#6333FF',
      50: '#B199FF',
      100: '#A185FF',
      200: '#9270FF', // Dark mode
      300: '#825CFF',
      400: '#7247FF',
      500: '#6333FF', // Main
      600: darken(0.04, '#6333FF'), // Hover
      700: darken(0.06, '#6333FF'), // Clicked
      800: '#531FFF',
      900: '#430AFF',
    },
  },
  fonts: {
    body: "'Inter', 'Work Sans', sans-serif",
    heading: "'Inter', 'Work Sans', sans-serif",
  },
  zIndices: {
    portals: 1500,
    modal: 1400,
    overlay: 1300,
  },
  transition: {
    duration: {
      background: '800ms',
      color: '600ms',
      normalSlow: '250ms',
    },
  },
  shadows: {
    outline: '0 0 0 3px #A8B1F0',
  },
  config: {
    initialColorMode: 'dark',
    // useSystemColorMode: true,
  },
}

export default extendTheme(customTheme)
