const storageKey = '__GUARDO__'

function userStorage() {
  let booted = false
  let data = {
    rememberLogin: {
      remember: false,
      email: '',
    },
  }

  return {
    boot() {
      booted = true
      if (!localStorage.getItem(storageKey)) {
        localStorage.setItem(storageKey, JSON.stringify({ ...data }))
      } else {
        data = {
          ...data,
          ...JSON.parse(localStorage.getItem(storageKey)),
        }
      }
    },

    wasBooted() {
      return booted
    },

    getData() {
      return { ...data }
    },

    getSessionId() {
      return data.sessionId
    },

    saveData(newData) {
      localStorage.setItem(storageKey, JSON.stringify(newData))
    },

    setData(newData) {
      data = { ...data, ...newData }
      this.saveData(data)
    },

    getDataByKey(key) {
      return data[key]
    },
  }
}

export default userStorage()
