import NProgress from 'nprogress'
import Router from 'next/router'
import { keyframes, css, Global } from '@emotion/react'
import theme from '../../constants/theme'

NProgress.configure({ showSpinner: false })

Router.onRouteChangeStart = () => {
  NProgress.start()
}
Router.onRouteChangeComplete = () => {
  NProgress.done()
}

Router.onRouteChangeError = () => {
  NProgress.done()
}

const nprogressSpinner = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const styles = color => css`
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${color};
    position: fixed;
    z-index: 999999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
  }

  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
    opacity: 1;
    transform: rotate(3deg) translate(0px, -4px);
  }

  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: ${color};
    border-left-color: ${color};
    border-radius: 50%;
    animation: ${nprogressSpinner} 400ms linear infinite;
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }
`

const color = theme.colors.primary.base
function Progress() {
  return <Global styles={styles(color)} />
}

export default Progress
