import { css, jsx } from '@emotion/react'
import { forwardRef } from 'react'
import { Box } from './chakra/Box'

// Note FLEX:
// default 0(flex-grow) 1(flex-shrink) auto(flex-basis)
// .item {
//   flex: 1;
// }
// I prefer flex: 0 0 auto

const styles = props => css`
  display: ${(props.flex && 'flex') ||
  (props.inlineFlex && 'inline-flex') ||
  (props.inlineBlock && 'inline-block') ||
  'block'};
  flex-direction: ${(props.column && 'column') || 'row'};
  flex-wrap: ${(props.nowrap && 'nowrap') || 'wrap'};
  ${props.order ? `order: ${props.order}` : ''};
  ${props.spaceBetween || props.spaceAround || props.spaceEvenly
    ? `justify-content: ${
        (props.spaceBetween && 'space-between') ||
        (props.spaceAround && 'space-around') ||
        (props.spaceEvenly && 'space-evenly') ||
        'flex-start'
      };`
    : ''};
  ${props.baseline || props.spaceEvenly
    ? `align-items: ${(props.baseline && 'baseline') || (props.spaceEvenly && 'space-evenly') || 'stretch'};`
    : ''};
  ${typeof props.flexNumber === 'number' ? `flex: ${props.flexNumber}` : ''};
  ${typeof props.grow === 'number' ? `flex-grow: ${props.grow}` : ''}; // Default 0, if 0 elements will not grow
  ${typeof props.shrink === 'number' ? `flex-shrink: ${props.shrink}` : ''}; // Default 1, if 0 elements will not shrink
  ${props.basis ? `flex-basis: ${props.basis}` : ''};
  ${props.center ? 'display: flex; align-items: center; justify-content: center;' : ''};
  ${props.centerH ? 'display: flex; justify-content: center; ' : ''};
  ${props.centerV ? 'display: flex; align-items: center;' : ''};
`

const Div = forwardRef(
  (
    {
      children,
      center,
      centerH,
      centerV,
      flex,
      flexNumber,
      inlineFlex,
      inlineBlock,
      column,
      nowrap,
      spaceBetween,
      spaceAround,
      spaceEvenly,
      order,
      baseline,
      grow,
      shrink,
      basis,
      css: propsCss,
      className,
      ...props
    },
    ref
  ) => {
    const stylesProps = {
      center,
      centerH,
      centerV,
      flex,
      flexNumber,
      inlineFlex,
      inlineBlock,
      column,
      nowrap,
      spaceBetween,
      spaceAround,
      spaceEvenly,
      order,
      baseline,
      grow,
      shrink,
      basis,
    }
    return (
      <Box ref={ref} {...props} className={`${propsCss} ${className}`} css={styles(stylesProps)}>
        {children}
      </Box>
    )
  }
)

export default Div
