import { forwardRef } from 'react'
import { AlertTitle as ChakraAlertTitle } from '@chakra-ui/react'

// https://chakra-ui.com/docs/feedback/alert

export const AlertTitle = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraAlertTitle ref={ref} {...props}>
      {props.children}
    </ChakraAlertTitle>
  )
})
