import { ChakraProvider, cookieStorageManager, localStorageManager } from '@chakra-ui/react'
import theme from '../../constants/theme'

function ChakraUI({ cookies, children }) {
  const colorModeManager = typeof cookies === 'string' ? cookieStorageManager(cookies) : localStorageManager

  return (
    <ChakraProvider colorModeManager={colorModeManager} theme={theme}>
      {children}
    </ChakraProvider>
  )
}

export default ChakraUI
