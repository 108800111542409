import { forwardRef } from 'react'
import { ModalHeader as ChakraModalHeader } from '@chakra-ui/react'

// https://chakra-ui.com/docs/overlay/modal

export const ModalHeader = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraModalHeader ref={ref} {...props}>
      {props.children}
    </ChakraModalHeader>
  )
})
