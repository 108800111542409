import { forwardRef } from 'react'
import { ModalBody as ChakraModalBody } from '@chakra-ui/react'

// https://chakra-ui.com/docs/overlay/modal

export const ModalBody = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraModalBody ref={ref} {...props}>
      {props.children}
    </ChakraModalBody>
  )
})
