import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import cloneDeep from 'lodash/cloneDeep'
import { sidebarWidthDefault } from '../constants'
import listToTree from '../utils/listToTree'

export const recoilKey = '$-GUARDO--RECOIL-$'
const { persistAtom } = recoilPersist({ key: recoilKey })

export const dashboardActiveItem = atom({
  key: 'dashboardActiveItem',
  default: 'bookmarks|all',
  // effects_UNSTABLE: [persistAtom],
})

export const premiumPlanModal = atom({
  key: 'premiumPlanModal',
  default: false,
})

export const appHeight = atom({
  key: 'appHeight',
  default: { px: '100vh', number: 0 },
})

export const revalidateOnFocus = atom({
  key: 'revalidateOnFocus',
  default: true,
})

export const openFoldersNav = atom({
  key: 'openFoldersNav',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export const installPWAEvent = atom({
  key: 'installPWAEvent',
  default: null,
})

export const sidebarWidth = atom({
  key: 'sidebarWidth',
  default: sidebarWidthDefault,
  effects_UNSTABLE: [persistAtom],
})

export const imagesFailedToLoad = atom({
  key: 'imagesFailedToLoad',
  default: {},
})

export const bookmarksViewMode = atom({
  key: 'bookmarksViewMode',
  default: {
    viewLikeCard: true,
    showTags: true,
    showUrl: true,
    showDescription: true,
    showDate: false,
  },
  effects_UNSTABLE: [persistAtom],
})

export const tagsViewMode = atom({
  key: 'tagsViewMode',
  default: {
    showCount: true,
    showDescription: true,
    showDate: false,
  },
  effects_UNSTABLE: [persistAtom],
})

export const tagsSortMode = atom({
  key: 'tagsSortMode',
  default: '-createdAt',
  effects_UNSTABLE: [persistAtom],
})

export const bookmarksSearchMode = atom({
  key: 'bookmarksSearchMode',
  default: {
    title: true,
    description: true,
    url: true,
    tags: true,
    text: false,
  },
  effects_UNSTABLE: [persistAtom],
})

export const tagsSearchMode = atom({
  key: 'tagsSearchMode',
  default: {
    name: true,
    description: false,
  },
  effects_UNSTABLE: [persistAtom],
})

export const bookmarksSortMode = atom({
  key: 'bookmarksSortMode',
  default: '-createdAt',
  effects_UNSTABLE: [persistAtom],
})

export const folderSortMode = atom({
  key: 'folderSortMode',
  default: 'creationDateDes',
  effects_UNSTABLE: [persistAtom],
})

export const folders = atom({
  key: 'folders',
  default: null,
})

export const tags = atom({
  key: 'tags',
  default: null,
})

export const bookmarks = atom({
  key: 'bookmarks',
  default: null,
})

export const treeFolders = selector({
  key: 'treeFolders',
  get: ({ get }) => {
    const folderList = get(folders)
    return folderList ? listToTree(cloneDeep(folderList)) : null
  },
})

export const favouritesBookmarks = selector({
  key: 'favouritesBookmarks',
  get: ({ get }) => {
    const bookmarkList = get(bookmarks)
    return bookmarkList ? bookmarkList.filter(({ favourite }) => favourite) : null
  },
})

export const monitoredBookmarks = selector({
  key: 'monitoredBookmarks',
  get: ({ get }) => {
    const bookmarkList = get(bookmarks)
    return bookmarkList ? bookmarkList.filter(({ isMonitored }) => isMonitored) : null
  },
})

export const uncategorizedBookmarks = selector({
  key: 'uncategorizedBookmarks',
  get: ({ get }) => {
    const bookmarkList = get(bookmarks)
    return bookmarkList ? bookmarkList.filter(({ folder }) => !folder) : null
  },
})

export const favouritesCount = selector({
  key: 'favouritesCount',
  get: ({ get }) => {
    const favouritesBookmarksList = get(favouritesBookmarks)
    return favouritesBookmarksList ? favouritesBookmarksList.length : null
  },
})

export const monitoredCount = selector({
  key: 'monitoredCount',
  get: ({ get }) => {
    const monitoredBookmarksList = get(monitoredBookmarks)
    return monitoredBookmarksList ? monitoredBookmarksList.length : null
  },
})

export const uncategorizedCount = selector({
  key: 'uncategorizedCount',
  get: ({ get }) => {
    const uncategorizedBookmarksList = get(uncategorizedBookmarks)
    return uncategorizedBookmarksList ? uncategorizedBookmarksList.length : null
  },
})

export const bookmarksCount = selector({
  key: 'bookmarksCount',
  get: ({ get }) => {
    const bookmarkList = get(bookmarks)
    return bookmarkList ? bookmarkList.length : null
  },
})

export const foldersCount = selector({
  key: 'foldersCount',
  get: ({ get }) => {
    const folderList = get(folders)
    return folderList ? folderList.length : null
  },
})

export const tagsCount = selector({
  key: 'tagsCount',
  get: ({ get }) => {
    const tagList = get(tags)
    return tagList ? tagList.length : null
  },
})

export const foldersBookmarks = selector({
  key: 'foldersBookmarks',
  get: ({ get }) => {
    const bookmarkList = get(bookmarks)

    return bookmarkList
      ? bookmarkList.reduce((acc, current) => {
          if (current.folder) {
            acc[current.folder] = [...(acc[current.folder] || []), current]
          }
          return acc
        }, {})
      : {}
  },
})

export const tagsByBookmarksCount = selector({
  key: 'tagsByBookmarksCount',
  get: ({ get }) => {
    const bookmarkList = get(bookmarks)

    return bookmarkList
      ? bookmarkList.reduce((acc, current) => {
          if (current?.tags) {
            current.tags?.forEach(tagId => {
              acc[tagId] = (acc[tagId] || 0) + 1
            })
          }
          return acc
        }, {})
      : null // Leave this as null, for loading reasons
  },
})

export const tagsByName = selector({
  key: 'tagsByName',
  get: ({ get }) => {
    const tagList = get(tags)

    return tagList
      ? tagList.reduce((acc, current) => {
          if (!acc[current?.name]) {
            acc[current.name] = current
          }
          return acc
        }, {})
      : null // Leave this as null, for loading reasons
  },
})

export const counts = selector({
  key: 'counts',
  get: ({ get }) => {
    const favourites = get(favouritesCount)
    const monitored = get(monitoredCount)
    const uncategorized = get(uncategorizedCount)
    const bookmarksListCount = get(bookmarksCount)
    const tagsListCount = get(tagsCount)

    return {
      favourites,
      monitored,
      uncategorized,
      bookmarks: bookmarksListCount,
      tags: tagsListCount,
    }
  },
})
