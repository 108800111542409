function listToTree(listParam) {
  const list = listParam
  const map = {}
  let node
  const roots = []
  let i

  for (i = 0; i < list.length; i += 1) {
    map[list[i]._id] = i // initialize the map
    list[i].children = [] // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i]
    if (node.parent) {
      // if you have dangling branches check that map[node.parent] exists
      list[map[node.parent]].children.push(node)
    } else {
      roots.push(node)
    }
  }

  return roots
}

export default listToTree
