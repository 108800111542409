import { css, jsx } from '@emotion/react'
import { forwardRef } from 'react'
import theme from '../constants/theme'
import { Box } from './chakra/Box'

const styles = props => css`
  margin-right: ${props.left ? '0.5rem' : '0'};
  margin-left: ${props.right ? '0.5rem' : '0'};
  display: inline-block;
  font-size: ${props.size ? `calc(${props.size}* ${theme.space[2]})` : theme.space[5]};
  //font-family: 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Emoji', Times, Symbola, Aegyptus, Code2000, Code2001,
  //  Code2002, Musica, serif, LastResort;
`

const Emoji = forwardRef(({ children, size, left, right, css: propsCss = '', className = '', ...props }, ref) => {
  return (
    <Box
      ref={ref}
      {...props}
      className={`${propsCss} ${className}`}
      css={styles({ size, left, right })}
      role="img"
      aria-label="emoji image"
    >
      {children}
    </Box>
  )
})

export default Emoji
