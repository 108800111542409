import { forwardRef } from 'react'
import { ModalFooter as ChakraModalFooter } from '@chakra-ui/react'

// https://chakra-ui.com/docs/overlay/modal

export const ModalFooter = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraModalFooter ref={ref} {...props}>
      {props.children}
    </ChakraModalFooter>
  )
})
