/* eslint-disable import/order */
import 'react-modal-video/css/modal-video.css'
// import '../../public/passiveEventListener' // This make event passive true WARNING if we need to prevent default
import { use100vh } from 'react-div-100vh'
import Page from '../components/page'
import { useState, useEffect } from 'react'

// see: https://nextjs.org/docs/advanced-features/custom-app
function MyApp({ Component, pageProps }) {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])
  const height = use100vh()

  return (
    <Page cookies={pageProps.cookies} session={pageProps.session} height={height}>
      {isClient && <Component {...pageProps} />}
    </Page>
  )
}

export async function getServerSideProps(context) {
  return {
    props: {
      ...context,
      // first time users will not have any cookies and you may not return
      // undefined here, hence ?? is necessary this for handling chakra UI dark mode
      cookies: context?.req?.headers?.cookie ?? '',
    },
  }
}

export default MyApp
