// eslint-disable-next-line no-console
const logFunction = process.env.NODE_ENV !== 'production' ? console.log : console.warn

function browserLog(...log) {
  if (process.browser && process.env.NEXT_PUBLIC_LOCAL_MODE) {
    logFunction('🟣', ...log)
  }
}

export default browserLog
