import axios from 'axios'
// import axiosRetry from 'axios-retry'
import { Toast } from '../components/chakra/Toast'
import logOutAndRedirect from '../utils/logOutAndRedirect'
import { deleteCookies } from '../utils/clearSiteData'
import { analyticsTrack } from '../utils/analytics'

const api = axios.create({
  baseURL: '/api',
})

// function exponentialDelay(retryNumber = 0) {
//   // eslint-disable-next-line no-restricted-properties
//   const seconds = Math.pow(2, retryNumber) * 1000
//   const randomMs = 1000 * Math.random()
//   return seconds + randomMs
// }

// axiosRetry(api, { retries: 1, retryDelay: exponentialDelay })

async function cleanAndLogout() {
  await deleteCookies()
  await logOutAndRedirect('/login')
  Toast.close('redirect-toast-unique')
  Toast({
    id: 'redirect-toast-unique',
    title: `Session has expired`,
    description: 'Please re-authenticate',
    status: 'warning',
    duration: 9000,
    isClosable: true,
    position: 'top-right',
  })
}

function handleError(err) {
  if (err.response && (err.response.status === 401 || err.response.status === 599)) {
    analyticsTrack('session_expired', { status: err.response.status })
    cleanAndLogout()
  }

  return err
}

// Add a response interceptor
api.interceptors.response.use(
  response => {
    // Do something with response data
    return response
  },
  async error => {
    // Do something with response error
    handleError(error)
    return Promise.reject(error)
  }
)

export default api
