import { css, Global } from '@emotion/react'
import theme from '../../constants/theme'

const styles = css`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

  body {
    font-family: ${theme.fonts.body};
  }
`

function Fonts() {
  return <Global styles={styles} />
}

export default Fonts
