import NProgress from 'nprogress'
import { signOut } from 'next-auth/react'
import Router from 'next/router'
import { deleteCookies } from './clearSiteData'
import { analyticsReset } from './analytics'

async function logOutAndRedirect(redirect) {
  const redirectPath = redirect || ''
  NProgress.start()
  try {
    await signOut({
      // callbackUrl: `${document.location.origin}${redirectPath}`,
      redirect: false,
    })
    analyticsReset()
  } catch (e) {
    await deleteCookies()
    return Promise.reject(e)
  }
  await Router.push(redirectPath)
  return Promise.resolve()
}

export default logOutAndRedirect
