import { css, jsx } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import Icon from './Icon'
import logoLight from '../assets/svg/BackToItLight.svg'
import logoDark from '../assets/svg/BackToItDark.svg'
import theme from '../constants/theme'

const styles = {
  logo: css`
    transition: all ${theme.transition.duration.normalSlow};
  `,
}

function Logo(props) {
  const { colorMode } = useColorMode()
  const darkModeActive = colorMode === 'dark'

  return <Icon icon={darkModeActive ? logoLight : logoDark} {...props} css={styles.logo} />
}

export default Logo
