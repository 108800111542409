import { forwardRef } from 'react'
import { AlertDescription as ChakraAlertDescription } from '@chakra-ui/react'

// https://chakra-ui.com/docs/feedback/alert

export const AlertDescription = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraAlertDescription ref={ref} {...props}>
      {props.children}
    </ChakraAlertDescription>
  )
})
