import { useEffect } from 'react'
import throttle from 'lodash/throttle'

function useWindowEvent(event, handler, throttleMs, shouldRun = true) {
  useEffect(() => {
    if (!shouldRun) return
    // use throttle
    const handlerFc = throttleMs ? throttle(handler, throttleMs) : handler
    // initiate the event handler
    window.addEventListener(event, handlerFc)

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener(event, handlerFc)
    }
  }, [])
}

export default useWindowEvent
