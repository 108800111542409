import Router from 'next/router'
import Analytics from 'analytics'
import mixpanelPlugin from '@analytics/mixpanel'
import { useEffect } from 'react'
import isAdmin from './isAdmin'
import { useUser } from '../dataHooks'
import { publicSiteUrl } from '../constants'

const shouldTrack = process.env.NODE_ENV === 'production' && !isAdmin()

const analytics = Analytics({
  debug: false,
  app: 'Guardo',
  plugins:
    typeof window !== 'undefined'
      ? [
          mixpanelPlugin({
            token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
            options: {
              api_host: `${publicSiteUrl}/guardoanre`,
              app_host: `${publicSiteUrl}/guardoanre`,
              cdn: `${publicSiteUrl}/guardoanre`,
            },
          }),
        ]
      : [],
})

if (typeof window !== 'undefined') {
  window.Analytics = analytics
}

export const useAnalyticsPage = () => {
  useEffect(() => {
    // Fire initial page view
    if (shouldTrack) {
      // analytics.page()
    }
    // Fire page views on routing
    // eslint-disable-next-line no-unused-vars
    const handleRouteChange = url => {
      if (shouldTrack) {
        // We need to wrap it in a rAF to ensure the correct data is sent to Segment
        // https://github.com/zeit/next.js/issues/6025
        // requestAnimationFrame(() => {
        //   analytics.page()
        // })
      }
    }

    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => Router.events.off('routeChangeComplete', handleRouteChange)
  }, [])

  return analytics
}

export const useAnalyticsIdentify = () => {
  const { user, loadingUser } = useUser()

  useEffect(() => {
    if (!loadingUser && user._id && shouldTrack) {
      // analytics.identify(user._id, {
      //   $created: user.createdAt,
      //   $name: user.name,
      //   $email: user.email,
      //   $avatar: user.image,
      // })
    }
  }, [user, loadingUser])
}

export const analyticsTrack = (itemEvent, payload) => {
  if (shouldTrack) {
    // analytics.track(itemEvent, payload)
  }
}

export const analyticsReset = () => {
  if (shouldTrack) {
    // analytics.reset()
  }
}

export default analytics
