import { memo, useEffect } from 'react'
import SemVersion from '@pearnaly/semantic-version'
import pkg from '../../../package.json'
import { useGetServerVersion } from '../../services/version'
import browserLog from '../../utils/browserLog'
import { Toast } from '../chakra/Toast'
import isPWA from '../../utils/isPWA'
import { Text } from '../chakra/Text'
import { Button } from '../chakra/Button'

function ServerVersionReloadApp() {
  const { version } = useGetServerVersion()

  useEffect(() => {
    async function checkVer() {
      try {
        const serverVersion = version
        const clientVersion = pkg.version
        browserLog('server', serverVersion)
        browserLog('client', clientVersion)
        if (SemVersion.gt(serverVersion, clientVersion)) {
          const text = isPWA()
            ? {
                title: 'Reopen the App',
                description:
                  'There is an important update, please reopen the App (close and open again) to download the latest version',
              }
            : {
                title: 'Reload the page',
                description: (
                  <div>
                    <Text block mb="10px">
                      There is an important update, please reload to download the latest version
                    </Text>
                    <Button size="xs" onClick={() => window?.location?.reload()} variant="outline" colorScheme="white">
                      Reload
                    </Button>
                  </div>
                ),
              }
          Toast.close('app-is-version-behind')
          Toast({
            id: 'app-is-version-behind',
            status: 'warning',
            duration: 20000,
            isClosable: true,
            position: 'top-right',
            ...text,
          })
        }
      } catch {
        // Fail silently
      }
    }

    checkVer()
  }, [version])

  return null
}

export default memo(ServerVersionReloadApp)
