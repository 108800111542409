import { Component } from 'react'
import UncaughtAppError from '../UncaughtAppError'
import { analyticsTrack } from '../../utils/analytics'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: error }
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.warn('Uncaught Error: ', error)
    // eslint-disable-next-line no-console
    console.warn('Error Info: ', errorInfo)
    analyticsTrack('error_app_uncaught', { error, errorInfo })
    this.setState({ hasError: true })
  }

  render() {
    const { children } = this.props
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.hasError ? <UncaughtAppError /> : children
  }
}

export default ErrorBoundary
