import { forwardRef } from 'react'
import { Box as ChakraBox } from '@chakra-ui/react'

// https://chakra-ui.com/docs/features/style-props
// https://chakra-ui.com/docs/layout/box

export const Box = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraBox ref={ref} {...props}>
      {props.children}
    </ChakraBox>
  )
})
