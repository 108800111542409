import { forwardRef } from 'react'
import { Modal as ChakraModal } from '@chakra-ui/react'
import useResponsive from '../../../utils/hooks/useResponsive'

// https://chakra-ui.com/docs/overlay/modal

export const Modal = forwardRef(({ size, ...props }, ref) => {
  const modalSize = useResponsive('full', size || 'md')

  return (
    <ChakraModal ref={ref} {...props} size={modalSize}>
      {props.children}
    </ChakraModal>
  )
})
