import { css, Global } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import { useSetRecoilState } from 'recoil'
import { memo, useEffect } from 'react'
import theme from '../../constants/theme'
import isPWA from '../../utils/isPWA'
import mobileAndTabletCheck from '../../utils/mobileAndTabletCheck'
import { appHeight } from '../../store'
import useWindowEvent from '../../utils/hooks/useWindowEvent'
import { useShowAccented } from '../../dataHooks'

const styles = props => css`
  :not(.not-selectable) {
    ::selection {
      color: ${theme.colors.white};
      background-color: ${theme.colors.primary.base};
    }
  }

  html {
    height: ${props.height}px;
    overflow: hidden;
  }

  body {
    color: ${props.darkModeActive ? theme.colors.text.dark : theme.colors.text.light};
    background: ${props.darkModeActive ? props.richBackground : theme.colors.background.light};
    transition: background ${theme.transition.duration.background}, color ${theme.transition.duration.color};
    /* mobile viewport bug fix */
    min-height: ${props.height}px;
    height: ${props.height}px;
    ${props.lockScroll &&
    css`
      position: fixed !important;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    `}
  }

  #__next {
    height: 100%;
  }

  // custom scrollbar
  .custom-scrollbar-guardo {
    ::-webkit-scrollbar {
      width: 6px;
      border-radius: 2px;
    }

    ::-webkit-scrollbar-track {
      background: none;
      border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.primary[500]};
      border-radius: 2px;
    }

    ::-webkit-scrollbar-corner {
      background: none;
    }
    scrollbar-color: ${theme.colors.primary[500]} ${theme.colors.primary[100]};
  }

  //custom portals
  #portal-components {
    position: relative;
    z-index: ${theme.zIndices.portals};
  }

  //crisp chat
  #crisp-chatbox a[role='button'] {
    transform: scale(0.8);
    bottom: 25px !important;

    & > span {
      background-color: ${theme.colors.primary.base} !important;
    }
  }

  // header loading blocks
  .header-loading-blocks-large {
    display: inline-block;
  }

  .header-loading-blocks-small {
    display: none;
  }

  .header-loading-blocks-main-button {
    width: 150px;
  }

  @media (max-width: 768px) {
    .header-loading-blocks-large {
      display: none;
    }

    .header-loading-blocks-small {
      display: inline-block;
    }

    .header-loading-blocks-main-button {
      width: 112px;
    }
  }

  // youtube modal
  .modal-video-movie-wrap {
    border: 4px solid white;
    border-radius: 4px;
  }

  .modal-video-close-btn {
    overflow: visible;
  }

  // Sleek widget
  #sleek-button {
    display: none !important;
  }
  .react-tel-input .flag-dropdown.open .selected-flag {
    background: ${props.darkModeActive ? props.richBackground : theme.colors.background.light} !important;
    border: 1px solid ${theme.colors.border[props.darkModeActive ? 'dark' : 'light']};
  }
  .react-tel-input .country-list .country.highlight,
  .react-tel-input .country-list .country:hover {
    background: ${props.darkModeActive ? '#4c4646' : theme.colors.background.light} !important;
  }
  .react-tel-input .flag-dropdown:hover,
  .react-tel-input .flag-dropdown:hover .selected-flag,
  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background: ${props.darkModeActive ? '#4c4646' : theme.colors.background.light} !important;
  }
`

function GlobalStyles({ height }) {
  const setAppHeight = useSetRecoilState(appHeight)
  useEffect(() => setAppHeight({ px: `${height}px`, number: height }), [height, setAppHeight])
  const richBackground = useShowAccented({
    accented: theme.colors.background.richBlack,
    normal: theme.colors.background.dark,
  })

  const { colorMode } = useColorMode()
  const darkModeActive = colorMode === 'dark'
  const lockScroll = isPWA() && mobileAndTabletCheck()

  useWindowEvent(
    'scroll',
    e => {
      e.preventDefault()
      window.scrollTo(0, 0)
    },
    null,
    isPWA() && mobileAndTabletCheck()
  )

  return <Global styles={styles({ darkModeActive, lockScroll, height, richBackground })} />
}

export default memo(GlobalStyles)
