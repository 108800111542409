function responsive(breakpoint, base, sm, md, lg, xl) {
  switch (breakpoint) {
    case 'base':
      return base
    case 'sm':
      return sm ?? base
    case 'md':
      return md ?? sm ?? base
    case 'lg':
      return lg ?? md ?? sm ?? base
    case 'xl':
      return xl ?? lg ?? md ?? sm ?? base
    default:
      return xl ?? lg ?? md ?? sm ?? base
  }
}

export default responsive
