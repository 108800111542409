import Meta from './Meta'
import Fonts from './Fonts'
import Progress from './Progress'
import GlobalStyles from './GlobalStyles'
import ResetStyles from './ResetStyles'
import ErrorBoundary from './ErrorBoundary'
import ChakraUI from './ChakraUI'
import Recoil from './Recoil'
import NextAuth from './NextAuth'
import ProtectedRoutes from './ProtectedRoutes'
import UserStorage from './UserStorage'
import Seo from './Seo'
import Crisp from '../Crisp'
import Sleek from '../Sleek'
import AnalyticsImp from './AnalyticsImp'
import Swr from './Swr'
import PremiumPlanModal from '../PremiumPlanModal'
import ServerVersionReloadApp from './ServerVersionReloadApp'
import AppOfflineIndicator from './AppOfflineIndicator'

function Page({ cookies, session, height, children }) {
  return (
    <NextAuth session={session}>
      <ChakraUI cookies={cookies}>
        <Recoil>
          <>
            <Meta />
            <Seo />
            <ResetStyles />
            <GlobalStyles height={height} />
            <Fonts />
            <Progress />
            <AnalyticsImp />
            <ServerVersionReloadApp />
            <ErrorBoundary>
              <Swr>
                <>
                  <ProtectedRoutes>
                    <Crisp />
                    <Sleek />
                    <UserStorage>{children}</UserStorage>
                  </ProtectedRoutes>
                  <PremiumPlanModal />
                  <AppOfflineIndicator />
                </>
              </Swr>
            </ErrorBoundary>
          </>
        </Recoil>
      </ChakraUI>
    </NextAuth>
  )
}

export default Page
