import { useRecoilState } from 'recoil'
import { useRouter } from 'next/router'
import Alert from './Alert'
import { premiumPlanModal as recoilPremiumPlanModal } from '../store'

function PremiumPlanModal() {
  const router = useRouter()
  const [premiumPlanModal, setPremiumPlanModal] = useRecoilState(recoilPremiumPlanModal)

  return premiumPlanModal ? (
    <Alert
      isOpen
      title="Premium feature"
      body="This is a premium feature 🙁 upgrade your plan to have full access ✨"
      successLabel="Upgrade"
      onClose={() => setPremiumPlanModal(false)}
      onSuccess={() => {
        router.push('/pricing')
        setPremiumPlanModal(false)
      }}
    />
  ) : null
}

export default PremiumPlanModal
