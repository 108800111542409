import { css, jsx } from '@emotion/react'
import { Button as ChakraButton, LightMode } from '@chakra-ui/react'
import { forwardRef, useMemo } from 'react'
import theme from '../../constants/theme'
import { Link } from './Link'
import { Box } from './Box'

// https://chakra-ui.com/docs/form/button

const styles = {
  button: css`
    font-family: ${theme.fonts.body};
    text-decoration: none !important;
  `,
  resetButton: css`
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    box-shadow: none !important;
  `,
}

export const Button = forwardRef(
  ({ lightMode, reset, css: propsCss = '', className = '', to, isExternal, ...props }, ref) => {
    const disabled = props.isDisabled || props.disabled
    const navigateProp = useMemo(
      () => (to && !disabled ? { href: to, as: Link, isExternal } : {}),
      [to, disabled, isExternal]
    )

    const ChakraButtonComp = (
      <ChakraButton ref={ref} {...props} {...navigateProp} className={`${propsCss} ${className}`} css={styles.button}>
        {props.children}
      </ChakraButton>
    )

    return (
      (lightMode && <LightMode>{ChakraButtonComp}</LightMode>) ||
      (reset && (
        <Box
          {...props}
          {...navigateProp}
          as={to ? Link : 'button'}
          className={`${propsCss} ${className}`}
          css={styles.resetButton}
        />
      )) ||
      ChakraButtonComp
    )
  }
)
