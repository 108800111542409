import { Fragment, useEffect } from 'react'
import { css, jsx } from '@emotion/react'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useColorMode } from '@chakra-ui/react'
import theme from '../../constants/theme'
import { useIsLoggedIn } from '../../dataHooks'

const styles = {
  loadingPanel: color => css`
    height: 100%;
    background: ${theme.colors.background[color]};
  `,
}

const privateRoutes = ['/dashboard', '/fav', '/new', '/pricing']
const authenticatedPrivateRoutes = ['/login', '/email-sent']

function ProtectedRoutes({ children }) {
  const { isLoggedIn, loadingSession, session } = useIsLoggedIn()
  const { colorMode } = useColorMode()
  const router = useRouter()
  const { pathname } = router
  const isPrivate = privateRoutes.some(item => pathname.includes(item))
  const isAuthenticatedPrivate = authenticatedPrivateRoutes.some(item => pathname.includes(item))

  useEffect(() => {
    const userEmail = session?.user?.email
    if (userEmail) {
      window.guardo = { userEmail }
    }
  }, [session?.user?.email])

  useEffect(() => {
    if (typeof loadingSession === 'boolean' && loadingSession) {
      NProgress.start()
      return
    }
    if (typeof loadingSession === 'boolean' && !loadingSession) {
      NProgress.done()
    }
  }, [loadingSession])

  const loadingIsNill = loadingSession == null

  // is loading session on a private route or loading is null or undefined
  if (loadingIsNill || (loadingSession && isPrivate)) {
    return <div css={styles.loadingPanel(colorMode)} />
  }

  // is loading session on a private route
  if (loadingSession && !isPrivate) {
    return <Fragment>{children}</Fragment>
  }

  // session error
  if (session?.error) {
    router.replace({
      pathname: '/login',
    })
    return <div css={styles.loadingPanel(colorMode)} />
  }

  // user is legged in a login page thus redirect to dashboard
  if (!loadingSession && isLoggedIn && isAuthenticatedPrivate) {
    router.replace({
      pathname: '/dashboard',
    })
    return <div css={styles.loadingPanel(colorMode)} />
  }

  // user is not legged in a private route thus redirect to login
  if (!loadingSession && !isLoggedIn && isPrivate) {
    router.replace({
      pathname: '/login',
      query: { callbackUrl: `${document.location.origin}${router.asPath}` },
    })
    return <div css={styles.loadingPanel(colorMode)} />
  }

  return <Fragment>{children}</Fragment>
}

export default ProtectedRoutes
