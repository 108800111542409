import { Fragment } from 'react'
import userStorage from '../../utils/userStorage'
import browserLog from '../../utils/browserLog'

function UserStorage({ children }) {
  if (process.browser && !userStorage.wasBooted()) {
    userStorage.boot()
    browserLog('user storage was booted')
  }

  return <Fragment>{children}</Fragment>
}

export default UserStorage
