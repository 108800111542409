const emails = ['chris.pal@gmail.com']

function isAdmin() {
  if (typeof window !== 'undefined') {
    const userEmail = window?.guardo?.userEmail
    return !!emails.find(email => email === userEmail)
  }

  return false
}

export default isAdmin
