import { forwardRef } from 'react'
import { Link as ChakraLink } from '@chakra-ui/react'
import NextLink from '../NextLink'

// https://chakra-ui.com/docs/components/link

export const Link = forwardRef(({ isExternal, ...props }, ref) => {
  return isExternal ? (
    <ChakraLink ref={ref} target="_blank" rel="noopener noreferrer" {...props}>
      {props.children}
    </ChakraLink>
  ) : (
    <NextLink {...props}>
      <ChakraLink ref={ref} {...props}>
        {props.children}
      </ChakraLink>
    </NextLink>
  )
})
