import { useState } from 'react'
import useWindowEvent from './useWindowEvent'

export default function useIsBrowserOffline() {
  const [online, setOnline] = useState(true)

  useWindowEvent(
    'offline',
    () => {
      setOnline(false)
    },
    null
  )

  return online
}
